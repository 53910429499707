<template>
  <v-container id="main" :class="classes.main">
    <h2 id="header" class="sm:text-lg" :class="classes.title">
      Payments & Transactions
    </h2>
    <v-tabs
      v-if="screenWidth >= 600"
      v-model="tab"
      class="tab-bar"
      hide-slider
      background-color="transparent"
      :show-arrows="true"
    >
      <v-tab
        v-for="(item, i) in tabItems"
        :key="i"
        class="single-tab-item xl:text-xl lg:text-xl"
        active-class="selected-tab"
        darks
      >
        {{ item.title }}
      </v-tab>
    </v-tabs>
    <v-select
      v-else
      id="select-component"
      v-model="tab"
      :items="selectItems"
      background-color="white"
      outlined
      :menu-props="{
        rounded: 'sm'
      }"
    ></v-select>
    <hr class="primary--text" :class="classes.content" />
    <div id="student-payment-transaction-tables" :class="classes.content">
      <v-tabs-items
        v-model="tab"
        style="background-color: transparent !important"
      >
        <v-tab-item v-for="(item, i) in tabItems" :key="i">
          <component :is="item.component"></component>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-container>
</template>

<script>
import Subscriptions from "#ef/payment-transaction/components/Subscriptions.vue";
import Transactions from "#ef/payment-transaction/components/Transactions.vue";
import { ResponsivenessUtils } from "/global/utils/mixins";

export default {
  name: "PaymentsTransactions",
  components: {
    Subscriptions,
    Transactions
  },
  mixins: [ResponsivenessUtils],
  data() {
    return {
      tab: 0,
      tabItems: [
        { title: "Subscriptions", component: "subscriptions" },
        { title: "Transactions", component: "transactions" }
      ],
      menuProp: {
        rounded: "xs"
      }
    };
  },
  computed: {
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
    selectItems() {
      return Object.entries(this.tabItems).map(entry => {
        return { text: entry[1].title, value: Number(entry[0]) };
      });
    },
    classes() {
      return {
        main: `pa-${this.breakPointValues(4, 4, 6, 8)} pb-0 ml-0`,
        title: `mb-${this.breakPointValues(4, 4, 8, 8)}`,
        content: `tab-content-${this.breakPointValues("sm", "md", "lg", "xl")}`
      };
    }
  },
  created() {
    if (this.$route.query.redirectTo) {
      let transactionId = this.$route.query.id;
      let transactionType = this.$route.query.type;
      let transactionStatus = this.$route.query.status;
      if (transactionId && transactionType && transactionStatus) {
        if (
          !["cancel", "aborted", "failure", "failed"].includes(
            transactionStatus
          )
        ) {
          window.location.href = this.$route.query.redirectTo;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.tab-bar {
  border-bottom-color: #6fc4e9 !important;
}

.single-tab-item {
  text-transform: none !important;
  color: #272727 !important;
  opacity: 1;
}

.selected-tab {
  background-color: #d6f3ff;
  border-radius: 10px 10px 0 0;
  opacity: 1;
}

.tab-content-xl {
  width: 1024px;
}

.tab-content-md {
  width: min(100%, 1024px);
}

.tab-content-lg {
  width: min(100%, 800px);
}

.tab-content-sm {
  width: 100%;
}
</style>
