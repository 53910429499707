<template>
  <v-container class="px-0">
    <v-card
      v-if="loading.status"
      class="elevation-0 d-flex justify-center align-center"
      :height="sizes.list.height"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-card>
    <v-card v-else-if="showNoItems" class="elevation-0">
      <no-content-component message="No transactions"></no-content-component>
    </v-card>
    <v-card v-else flat :class="classes.transactionCard">
      <!-- <v-card height="50" width="160" class="mb-5 pa-0 mx-0 elevation-0">
        <v-select
          v-model="selectedMonth"
          height="30"
          class="pa-0 justify-center"
          outlined
          placeholder="Select Month"
          label="Select Month"
          :items="[
            'Recent',
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ]"
        >
        </v-select>
      </v-card> -->
      <v-card
        v-if="paging.status"
        class="elevation-0 d-flex justify-center align-center"
        :height="sizes.list.height"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-card>
      <v-card
        v-else
        :height="sizes.list.height"
        class="flex-column overflow-x-hidden elevation-0"
      >
        <v-card
          v-for="(tr, i) in items"
          :key="i"
          class="elevation-0 border-default border"
          outlined
          color="#F8F8FA"
        >
          <v-row class="ma-0">
            <v-col
              v-if="screenWidth >= 600"
              :cols="cols.image"
              class="d-flex flex-column justify-center align-center"
            >
              <v-card flat width="43">
                <v-img
                  height="43"
                  width="43"
                  :src="
                    require('#ef/payment-transaction/assets/transaction_image.svg')
                  "
                ></v-img>
              </v-card>
              <div v-if="screenWidth <= 960" style="height: 50%"></div>
            </v-col>
            <v-col :cols="cols.middle">
              <v-row class="ma-0">
                <v-col
                  :cols="cols.inner.title"
                  :order="order.inner.title"
                  class="d-flex flex-column justify-center pl-0"
                >
                  <div
                    class="text-truncate xl:text-base lg:text-base md:text-base sm:text-base"
                  >
                    <b>{{ tr.title }}</b>
                  </div>
                  <div
                    class="text-truncate xl:text-sm lg:text-sm md:text-sm sm:text-sm"
                  >
                    Date: {{ dateFormat(tr.transaction_time) }}
                  </div>
                </v-col>
                <v-col
                  :cols="cols.inner.method"
                  :order="order.inner.method"
                  class="d-flex flex-column justify-center pl-0"
                >
                  <div
                    v-if="hasMethod(tr)"
                    class="pa-0 xl:text-sm lg:text-sm md:text-sm sm:text-sm"
                  >
                    Method: {{ tr.payment_method }}
                  </div>
                  <div
                    v-if="hasSessiondId(tr)"
                    class="pa-0 text-truncate xl:text-sm lg:text-sm md:text-sm sm:text-sm"
                  >
                    Transaction ID: {{ tr.payment_method_session_id }}
                  </div>
                  <div
                    v-else
                    class="pa-0 xl:text-sm lg:text-sm md:text-sm sm:text-sm"
                  >
                    Bypassed
                  </div>
                </v-col>
                <v-col
                  v-if="screenWidth >= 600"
                  :cols="cols.inner.amount"
                  :order="order.inner.amount"
                  class="d-flex flex-column justify-center pl-0"
                >
                  <div
                    v-if="wasSuccessful(tr)"
                    class="d-flex justify-end pl-0 primary--text xl:text-lg lg:text-lg md:text-lg sm:text-lg"
                  >
                    {{ numberFormat(tr.amount)
                    }}{{ currencySymbol[tr.currency] }}
                  </div>
                  <div
                    v-else-if="wasAborted(tr)"
                    class="d-flex justify-end pl-0 xl:text-lg lg:text-lg md:text-lg sm:text-lg decline--text"
                  >
                    Aborted
                  </div>
                  <div
                    v-else
                    class="d-flex justify-end pl-0 xl:text-lg lg:text-lg md:text-lg sm:text-lg decline--text"
                  >
                    Failed
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              :cols="cols.action"
              class="d-flex flex-column justify-center pa-0"
            >
              <v-menu rounded="sm">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon size="28">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list
                  class="elevation-0 rounded border-solid border-default border-1-2 py-0"
                >
                  <v-list-item
                    class="px-2 xl:text-sm lg:text-sm md:text-sm sm:text-sm"
                    :class="{
                      inert: !tr.payment_method_session_id
                    }"
                    @click="copySessionId(tr.payment_method_session_id)"
                  >
                    <div
                      :style="
                        `color: ${
                          tr.payment_method_session_id ? '#545454' : '#bdbdbd'
                        }`
                      "
                    >
                      &nbsp;Copy ID
                    </div>
                  </v-list-item>
                  <v-divider class="ma-0 divider-style"></v-divider>
                  <v-list-item
                    class="px-2 xl:text-sm lg:text-sm md:text-sm sm:text-sm"
                    :class="{ inert: !wasSuccessful(tr) }"
                    @click="showReceipt(tr.transaction_id)"
                  >
                    <div
                      :style="
                        `color: ${wasSuccessful(tr) ? '#545454' : '#bdbdbd'}`
                      "
                    >
                      &nbsp;View receipt
                    </div>
                  </v-list-item>
                  <v-divider class="ma-0 divider-style"></v-divider>
                  <v-list-item
                    class="px-2 xl:text-sm lg:text-sm md:text-sm sm:text-sm"
                    :class="{ inert: !wasSuccessful(tr) }"
                    @click="downloadReceipt(tr.transaction_id)"
                  >
                    <div
                      :style="
                        `color: ${wasSuccessful(tr) ? '#545454' : '#bdbdbd'}`
                      "
                    >
                      &nbsp;Download
                    </div>
                  </v-list-item>
                </v-list>
              </v-menu>
              <div v-if="screenWidth <= 960" style="height: 50%"></div>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
      <div v-if="showPaginator" class="mt-5">
        <v-pagination
          v-model="page"
          :length="pages"
          :total-visible="breakPointValues(2, 5, 7, 7)"
          color="primary"
        ></v-pagination>
      </div>
    </v-card>
    <v-dialog v-model="showReceiptMenu" width="75%" class="rounded">
      <receipt-dialog :contents="receiptContent"></receipt-dialog>
    </v-dialog>
  </v-container>
</template>

<script>
import ReceiptDialog from "#ecf/payment-transation/components/ReceiptDialog.vue";
import NoContentComponent from "/global/components/NoContentComponent.vue";
import { PaymentFetch } from "#ef/payment-transaction/mixins.js";
import { apiCallUtils } from "/global/utils/mixins";

export default {
  name: "Transactions",
  components: { ReceiptDialog, NoContentComponent },
  mixins: [PaymentFetch, apiCallUtils],
  data() {
    return {
      loading: {},
      paging: {},
      selectedMonth: null,
      page: 1,
      fetchingReceipt: false,
      receiptContent: undefined,
      showReceiptMenu: false
    };
  },
  computed: {
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
    classes() {
      return {
        transactionCard: `pa-${this.breakPointValues(3, 3, 5, 5)}`
      };
    },
    sizes() {
      return {
        list: {
          height: this.screenWidth < 961 ? 316 : 376
        }
      };
    },
    cols() {
      return {
        image: this.breakPointValues("0", "2", "1", "1"),
        middle: this.breakPointValues("10", "9", "10", "10"),
        inner: {
          title: this.breakPointValues("12", "6", "4", "4"),
          method: this.breakPointValues("12", "12", "5", "5"),
          amount: this.breakPointValues("0", "6", "3", "3")
        },
        action: this.breakPointValues("2", "1", "1", "1")
      };
    },
    order() {
      return {
        inner: {
          title: 1,
          method: this.breakPointValues(2, 3, 2, 2),
          amount: this.breakPointValues(2, 2, 3, 3)
        }
      };
    },
    currencySymbol() {
      return {
        bdt: "৳",
        usd: "$"
      };
    },
    pages() {
      return this.$store.state.transactionEF.transactionPages;
    },
    items() {
      let listItems = this.contents;
      if (listItems === undefined) return [];
      else return listItems[this.page];
    },
    contents() {
      return this.$store.getters["transactionEF/transactions"];
    },
    showNoItems() {
      return this.pages === 0 && this.items?.length === 0;
    },
    showPaginator() {
      return this.loading.status || this.pages > 0;
    }
  },
  watch: {
    page: {
      async handler(value) {
        if (!(value in this.contents)) {
          await this.waitingCall(
            async () => {
              await this.fetchContent("listTransactions", value);
            },
            this.paging,
            "status"
          );
        }
      }
    }
  },
  async created() {
    await this.waitingCall(
      async () => {
        await this.fetchPages("pagesTransaction");
        await this.fetchContent("listTransactions", { page: 1 });
      },
      this.loading,
      "status"
    );
  },
  methods: {
    async downloadReceipt(transactionId) {
      await this.waitingCall(
        async () => {
          const resp = await this.fetchContent("getReceipt", { transactionId });
          const fileUrl = resp?.receipt;
          window.open(fileUrl, "_blank");
        },
        {},
        "status"
      );
    },
    async showReceipt(transactionId) {
      await this.waitingCall(
        async () => {
          this.receiptContent = await this.fetchContent("showReceipt", {
            transactionId
          });
          this.showReceiptMenu = true;
        },
        {},
        "status"
      );
    },
    copySessionId(text) {
      navigator.clipboard.writeText(text);
    },
    breakPointValues(smValue, mdValue, lgValue, xlValue) {
      let s = this.screenWidth;
      return s < 600
        ? smValue
        : s < 961
        ? mdValue
        : s < 1903
        ? lgValue
        : xlValue;
    },
    dateFormat(date) {
      let out = new Date(date);
      return out.toLocaleString("en-gb", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
      });
    },
    numberFormat(number) {
      return Number(number).toFixed(2);
    },
    hasMethod(item) {
      return "payment_method" in item;
    },
    hasSessiondId(item) {
      return "payment_method_session_id" in item;
    },
    wasAborted(item) {
      return item.status === "aborted";
    },
    wasSuccessful(item) {
      if (!this.wasAborted(item)) return item.status === "success";
      else return false;
    }
  }
};
</script>

<style scoped lang="scss">
.divider-style {
  background-color: $primary;
  opacity: 0.1;
}
</style>
