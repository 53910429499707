<template>
  <v-container class="px-0">
    <v-sheet class="pa-6" :height="sizes.container">
      <v-card flat height="40">
        <h2
          class="xl:text-xl lg:text-xl md:text-lg sm:text-base d-flex align-center"
        >
          <span>Your Plan</span>
        </h2>
      </v-card>
      <v-card flat height="552">
        <v-row no-gutters>
          <v-col :cols="cols" :class="classes.left">
            <div style="height: 552px" class="overflow-y-auto plans-section">
              <details-card
                v-for="(plan, i) in plans"
                :id="`plan-card-${i}`"
                :key="i"
                :plan="plan"
                :class="[`mt-${i === 0 ? 0 : 3}`]"
                :is-selected="plan.id === selectedPlan"
                @click="v => (selectedPlan = v)"
              ></details-card>
            </div>
          </v-col>
          <v-col :cols="cols" :class="classes.right">
            <feature-card
              :plan-id="selectedPlan"
              @upgrade="updateSubscriptionPlan"
            ></feature-card>
          </v-col>
        </v-row>
      </v-card>
    </v-sheet>
  </v-container>
</template>

<script>
import DetailsCard from "#ef/payment-transaction/components/subscription/DetailsCard.vue";
import FeatureCard from "#ef/payment-transaction/components/subscription/FeatureCard.vue";
import { ResponsivenessUtils, apiCallUtils } from "/global/utils/mixins";
import { PaymentFetch } from "#ef/payment-transaction/mixins.js";

export default {
  name: "Subscriptions",
  components: {
    FeatureCard,
    DetailsCard
  },
  mixins: [PaymentFetch, ResponsivenessUtils, apiCallUtils],
  data() {
    return {
      loading: {},
      selectedPlan: undefined,
      tab: 0
    };
  },
  computed: {
    plans() {
      return this.$store.getters["transactionEF/plans"];
    },
    currentPlan() {
      return this.$store.state.user.user["current_plan"];
    },
    sizes() {
      return {
        container: this.screenWidth < 600 ? 1400 : 640
      };
    },
    cols() {
      return this.screenWidth < 600 ? "12" : "6";
    },
    classes() {
      return {
        left: this.screenWidth < 600 ? "" : "pr-2",
        right: this.screenWidth < 600 ? "" : "pl-2"
      };
    }
  },
  async created() {
    await this.waitingCall(
      async () => {
        await this.fetchContent("listPlans");
      },
      this.loading,
      "status"
    );
    this.selectedPlan = this.currentPlan || this.plans[0]["id"];
  },
  methods: {
    async updateSubscriptionPlan(planId) {
      await this.waitingCall(
        async () => {
          window.location.href = await this.fetchContent("purchasePlan", {
            planId,
            redirectTo: this.$route.query.redirectTo
          });
        },
        this.loading,
        "session"
      );
    }
  }
};
</script>

<style scoped lang="scss">
.plans-section {
  scrollbar-width: auto;
  scrollbar-color: $primary white;
}
</style>
