var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "px-0" },
    [
      _c(
        "v-sheet",
        { staticClass: "pa-6", attrs: { height: _vm.sizes.container } },
        [
          _c("v-card", { attrs: { flat: "", height: "40" } }, [
            _c(
              "h2",
              {
                staticClass:
                  "xl:text-xl lg:text-xl md:text-lg sm:text-base d-flex align-center",
              },
              [_c("span", [_vm._v("Your Plan")])]
            ),
          ]),
          _c(
            "v-card",
            { attrs: { flat: "", height: "552" } },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { class: _vm.classes.left, attrs: { cols: _vm.cols } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "overflow-y-auto plans-section",
                          staticStyle: { height: "552px" },
                        },
                        _vm._l(_vm.plans, function (plan, i) {
                          return _c("details-card", {
                            key: i,
                            class: [`mt-${i === 0 ? 0 : 3}`],
                            attrs: {
                              id: `plan-card-${i}`,
                              plan: plan,
                              "is-selected": plan.id === _vm.selectedPlan,
                            },
                            on: { click: (v) => (_vm.selectedPlan = v) },
                          })
                        }),
                        1
                      ),
                    ]
                  ),
                  _c(
                    "v-col",
                    { class: _vm.classes.right, attrs: { cols: _vm.cols } },
                    [
                      _c("feature-card", {
                        attrs: { "plan-id": _vm.selectedPlan },
                        on: { upgrade: _vm.updateSubscriptionPlan },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }