var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { class: _vm.classes.main, attrs: { id: "main" } },
    [
      _c(
        "h2",
        {
          staticClass: "sm:text-lg",
          class: _vm.classes.title,
          attrs: { id: "header" },
        },
        [_vm._v(" Payments & Transactions ")]
      ),
      _vm.screenWidth >= 600
        ? _c(
            "v-tabs",
            {
              staticClass: "tab-bar",
              attrs: {
                "hide-slider": "",
                "background-color": "transparent",
                "show-arrows": true,
              },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            _vm._l(_vm.tabItems, function (item, i) {
              return _c(
                "v-tab",
                {
                  key: i,
                  staticClass: "single-tab-item xl:text-xl lg:text-xl",
                  attrs: { "active-class": "selected-tab", darks: "" },
                },
                [_vm._v(" " + _vm._s(item.title) + " ")]
              )
            }),
            1
          )
        : _c("v-select", {
            attrs: {
              id: "select-component",
              items: _vm.selectItems,
              "background-color": "white",
              outlined: "",
              "menu-props": {
                rounded: "sm",
              },
            },
            model: {
              value: _vm.tab,
              callback: function ($$v) {
                _vm.tab = $$v
              },
              expression: "tab",
            },
          }),
      _c("hr", { staticClass: "primary--text", class: _vm.classes.content }),
      _c(
        "div",
        {
          class: _vm.classes.content,
          attrs: { id: "student-payment-transaction-tables" },
        },
        [
          _c(
            "v-tabs-items",
            {
              staticStyle: { "background-color": "transparent !important" },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            _vm._l(_vm.tabItems, function (item, i) {
              return _c(
                "v-tab-item",
                { key: i },
                [_c(item.component, { tag: "component" })],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }